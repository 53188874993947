<template>
  <b-modal
    v-model="isOpen"
    centered
    :title="isCall ? 'Создание запроса на звонок' : 'Создание запроса на СМС'"
    header-class="monitoring-create-call-header"
    @hidden="onClose"
    @ok="onClose"
    @close="onClose"
  >
    <template>
      <div class="flex justify-center">
        <div class="modal-body">
          <b-form-group label="Дата и время">
            <b-form-input
              :value="getFormatDate(date, 'dd.MM.yyyy HH:mm')"
              type="text"
              size="sm"
              disabled
            />
          </b-form-group>
          <v-select
            v-if="isCall"
            v-model="status"
            :reduce="item => item.value"
            :options="statuses"
            placeholder="Статус"
            label="title"
            class="crm-select"
            @input="validateField('status', status)"
          >
            <template #no-options>
              Ничего не найдено
            </template>
          </v-select>
          <div
            v-if="errors['status']"
            class="text-danger mt-2 small"
          >
            {{ errors['status'][0] }}
          </div>
          <b-form-group
            class="mt-2"
            label="Текст"
          >
            <b-form-textarea
              v-model="comment"
              placeholder="Введите текст"
              rows="3"
              size="sm"
              @input="validateField('comment', comment)"
            />
            <div
              v-if="errors['comment']"
              class="text-danger mt-2 small"
            >
              {{ errors['comment'][0] }}
            </div>
          </b-form-group>
          <div
            v-if="monitorings.length > 0"
            class="mt-3"
          >
            <span>{{ monitoringsTitle }}</span>
            <div class="numbers-block">
              <span
                v-for="monitoring in monitorings"
                :key="monitoring.id"
              >
                {{ monitoring.patient.userName }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </template>

    <template #modal-footer>
      <b-button
        variant="danger"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClose"
      >
        Отменить
      </b-button>

      <b-button
        variant="primary"
        :type="$const.PRIMARY_BUTTON"
        class="float-right"
        @click="onClickSave"
      >
        Сохранить
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { format } from '@evd3v/date-fns';
import { MONITORING_STATUS_TYPE } from '@/helpers/consts';
import { vtbFormService } from '@/services';
import { utc0ToDateWithUserTimezone } from '@/helpers/utils';
import { showErrorCustomMessage, showValidationErrorMessage } from '@/helpers/messages';
import { makeValidate, validateData } from '@/validation/zod/main/helper.validation';
import { monitoringCreateCallModalValidationSchema } from '@/validation/zod/monitoring-create-call-modal/monitoringCreateCallModal.validation';

export default {
  name: 'MonitoringCreateCallModal',
  props: {
    isCall: {
      type: Boolean,
      default: false,
    },
    monitorings: {
      type: Array,
      default: () => ([]),
    },
    patient: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      date: new Date().toISOString(),
      comment: '',
      status: '',
      isOpen: true,
      fieldsToValidate: ['comment'],
      errors: [],
    };
  },
  computed: {
    statuses() {
      return MONITORING_STATUS_TYPE;
    },
    monitoringsTitle() {
      return this.monitorings.length > 1 ? 'Выбранные номера' : 'Выбранный номер';
    },
  },
  created() {
    if (this.isCall) this.fieldsToValidate = ['comment', 'status'];
  },
  methods: {
    validateField(field, value) {
      const error = validateData(field, value, monitoringCreateCallModalValidationSchema);
      if (error) {
        this.$set(this.errors, field, error);
      } else {
        this.$delete(this.errors, field);
      }
    },
    makeValidate(fields) {
      const errors = makeValidate(fields, this, monitoringCreateCallModalValidationSchema);
      Object.keys(errors).forEach((field) => {
        this.$set(this.errors, field, errors[field]);
      });
    },
    getFormatDate(date, formatter) {
      return date && formatter ? format(new Date(date), formatter) : null;
    },
    onClose() {
      this.$emit('input', false);
    },
    async onClickSave() {
      this.makeValidate(this.fieldsToValidate);
      if (Object.keys(this.errors).length > 0) {
        showValidationErrorMessage();
        return;
      }
      const paramsArray = [];
      try {
        if (this.monitorings.length > 0) {
          const phoneNumbers = [];
          for (let i = 0; i < this.monitorings.length; i += 1) {
            const params = {
              patientId: this.monitorings[i].patient.id,
              date: format(utc0ToDateWithUserTimezone(this.date), 'yyyy-MM-dd'),
              type: this.isCall ? 'Call' : 'Sms',
              comment: this.comment,
              callStatus: this.status,
            };
            phoneNumbers.push({ phone: `+${this.monitorings[i].patient.userName}` });
            paramsArray.push(vtbFormService.setQuestionnaire(params));
          }
          paramsArray.push(this.$store.dispatch(this.$types.SMS_SENDING, {
            phoneNumbers,
            text: this.comment,
          }));
        } else {
          const params = {
            patientId: this.patient.id,
            date: format(utc0ToDateWithUserTimezone(this.date), 'yyyy-MM-dd'),
            type: this.isCall ? 'Call' : 'Sms',
            comment: this.comment,
            callStatus: this.status,
          };
          paramsArray.push(vtbFormService.setQuestionnaire(params));
          if (!this.isCall) {
            paramsArray.push(this.$store.dispatch(this.$types.MESSAGES_SEND_MESSAGE, {
              number: `+${this.patient.userName}`,
              text: this.comment,
            }));
          }
        }
        await Promise.all(paramsArray);
        await this.$emit('refreshSearch');
        this.onClose();
      } catch (e) {
        console.warn(e);
        showErrorCustomMessage('Не удалось создать запрос');
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.monitoring-create-call-header {
  font-weight: 500;
}
.modal-body {
  width: 100%;
  padding: 0 12px;
}
.numbers-block {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  border: 1px solid #E0E0E0;
  font-size: 14px;
  font-weight: 500;
  gap: 5px;
  padding: 4px;
  margin-top: 8px;
}
::v-deep.crm-select {
  .vs__dropdown-toggle {
    height: 100%;
  }

  .vs__dropdown-menu {
    display: flex;
    flex-direction: column;
    height: 350px;
    max-height: 350px;
  }
}
</style>
